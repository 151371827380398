export enum PathParameters {
  BOOKING_ID = 'bookingId',
  INVOICE_ID_OR_NUMBER = 'invoiceIdOrNumber',
}

export const paths = {
  checkout: '/trains/order',
  complete: '/complete',
  invoicePage: '/invoice',
  invoicesAndReceipts: '/invoices-and-receipts',
  order: '/order',
  passengers: '/passenger',
  payment: '/payment',
  paymentComplete: '/payment/complete',
  paymentProcessing: '/payment/processing',
  rootPath: '/',
  routePage: '/route',
  ticketDownload: '/tickets/download',
  timetable: '/timetable',
}

const routes = {
  checkout: `${paths.checkout}`,
  checkoutPassengers: `${paths.checkout}/:${PathParameters.BOOKING_ID}${paths.passengers}`,
  checkoutPayment: `${paths.checkout}/:${PathParameters.BOOKING_ID}${paths.payment}`,
  checkoutTimetable: `${paths.checkout}${paths.timetable}`,
  invoicePage: `${paths.ticketDownload}/:${PathParameters.INVOICE_ID_OR_NUMBER}`,
  invoicesAndReceipts: `${paths.invoicesAndReceipts}/:${PathParameters.BOOKING_ID}`,
  order: `${paths.order}/:${PathParameters.BOOKING_ID}`,
  orderComplete: `${paths.order}/:${PathParameters.BOOKING_ID}${paths.complete}`,
  rootPath: paths.rootPath,
  routePage: paths.routePage,
  ticketDownload: `${paths.ticketDownload}/:${PathParameters.BOOKING_ID}`,
}

export const getRoutes = {
  checkoutPassengers: (bookingId: string, ver?: string) =>
    `${ver ? `/v${ver}` : ''}${paths.checkout}/${bookingId}${paths.passengers}`,
  checkoutPayment: (bookingId: string, ver?: string) =>
    `${ver ? `/v${ver}` : ''}${paths.checkout}/${bookingId}${paths.payment}`,
  checkoutTimetable: (ver?: string) => `${ver ? `/v${ver}` : ''}${routes.checkoutTimetable}`,
  completeOrder: (bookingId: string) => `${paths.order}/${bookingId}${paths.complete}`,
  invoice: (invoiceIdOrNumber: number | string) => `${paths.invoicePage}/${invoiceIdOrNumber}`,
  invoicesAndReceipts: (bookingId: string) => `${paths.invoicesAndReceipts}/${bookingId}`,
  order: (bookingId: string) => `${paths.order}/${bookingId}`,
  relative: (path: string, ver?: string) => `${ver ? `/v${ver}` : ''}${path}`,
  rootPath: (ver?: string) => (ver ? `/v${ver}` : '/'),
  routePage: (path: string, ver?: string) => `${ver ? `/v${ver}` : ''}${paths.routePage}/${path}`,
}

export const getExperimentRoutes = {
  checkoutPassengers: (path: string, bookingId: string) => `${path}${paths.checkout}/${bookingId}${paths.passengers}`,
  checkoutPayment: (path: string, bookingId: string) => `${path}${paths.checkout}/${bookingId}${paths.payment}`,
}

export default routes
